export const storageConstants = {
  user: 'user',
  area: 'area',
  areas: 'areas',
  period: 'period',
  filters: 'filters',
  paginate: 'paginate',
  paginateUserEntities: 'paginateUserEntities',
  paginateUsers: 'paginateUsers',
  paginateBuckets: 'paginateBuckets',
  paginateInboundBuckets: 'paginateInboundBuckets',
  paginateInboundBucketsChld: 'paginateInboundBucketsChld',
  paginateInboundDocuments: 'paginateInboundDocuments',
  paginateLabels: 'paginateLabels',
  paginateOutboundBuckets: 'paginateOutboundBuckets',
  paginateOutboundDocuments: 'paginateOutboundDocuments',
  input: 'input',
  output: 'output',
  sub: 'sub',
  lang: 'lang'
};

export const paginateStorageconstant: any = {
  'home': storageConstants.paginate,
  'inbound': storageConstants.paginateInboundDocuments,
  'outbound': storageConstants.paginateOutboundBuckets
}
